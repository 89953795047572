import { Component } from '@angular/core';

import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { Issue } from '@shared/classes/model/backend/issue';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import {
  ApiResponseIssueEvent,
  AppPermissions,
  DataTypeEnum,
  EscalationDto,
  EscalationHistoryDto,
  IAction,
  IColumn,
  ItemBasePage,
  makePlural,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  OfflineConfig,
  RecordStatusEnumFilterWithoutDeleted,
  RemediationDto,
  RequestHandlerOptions,
  routeToLocaleCase,
  TargetTypeEnum,
  toCamelCase,
  toKebabCase,
} from 'app/shared/classes';
import { keyBy } from 'lodash-es';
import { combineLatest, forkJoin, takeUntil } from 'rxjs';
import { EscalationDataService } from '../../services/data/escalation-data.service';
import { EscalationHistoryDataService } from '../../services/data/escalation-history-data.service';
import { IssuesDataService } from '../../services/data/issues-data.service';

@Component({
  selector: 'app-issues-item',
  templateUrl: './issues-item.component.html',
  styleUrls: ['./issues-item.component.scss'],
})
export class IssuesItemComponent extends ItemBasePage<Issue> {
  realTimeFormData: any = {}; //Copy of form data that changes realtime when form changes
  showArchived = false;
  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: false,
  };
  toDraftAction: IAction = {
    id: 1,
    label: 'Return to Draft',
    buttonType: 'button',
    color: 'secondary',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Draft),
    icon: 'fas fa-clock-rotate-left',
  };

  toRemediationAction: IAction = {
    id: 2,
    label: 'Remediation',
    buttonType: 'button',
    color: 'info',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Remediation),
    icon: 'fas fa-user-clock',
  };
  toAnalysisAction: IAction = {
    id: 3,
    label: 'Analysis',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Analysis),
    icon: 'fas fa-check-double',
    color: 'success',
  };
  toClosedResolvedAction: IAction = {
    id: 4,
    label: 'Close As Resolved',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.ClosedResolved),
    icon: 'pi pi-check',
    color: 'success',
  };
  toClosedUnresolvedAction: IAction = {
    id: 5,
    label: 'Close As Unresolved',
    buttonType: 'button',
    color: 'danger',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.ClosedUnresolved),
    icon: 'pi pi-times',
  };
  toReviewAction: IAction = {
    id: 6,
    label: 'Review',
    buttonType: 'button',
    color: 'warning',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Review),
    icon: 'pi pi-user-edit',
  };
  cols: IColumn[] = [];
  offlineConfig: OfflineConfig = {
    lazy: false,
    paginator: true,
    showActionBar: false,
  };
  get actionsMap() {
    return {
      [ApiResponseIssueEvent.DataEnum.Analysis]: this.toAnalysisAction,
      [ApiResponseIssueEvent.DataEnum.ClosedResolved]: this.toClosedResolvedAction,
      [ApiResponseIssueEvent.DataEnum.ClosedUnresolved]: this.toClosedUnresolvedAction,
      [ApiResponseIssueEvent.DataEnum.Draft]: this.toDraftAction,
      [ApiResponseIssueEvent.DataEnum.Remediation]: this.toRemediationAction,
      [ApiResponseIssueEvent.DataEnum.Review]: this.toReviewAction,
    };
  }
  get statusChangeValue() {
    return {
      [ApiResponseIssueEvent.DataEnum.Analysis]: Issue.IssueStatusEnum.Analysis,
      [ApiResponseIssueEvent.DataEnum.ClosedResolved]: Issue.IssueStatusEnum.ClosedResolved,
      [ApiResponseIssueEvent.DataEnum.ClosedUnresolved]: Issue.IssueStatusEnum.ClosedUnresolved,
      [ApiResponseIssueEvent.DataEnum.Draft]: Issue.IssueStatusEnum.Draft,
      [ApiResponseIssueEvent.DataEnum.Remediation]: Issue.IssueStatusEnum.Remediation,
      [ApiResponseIssueEvent.DataEnum.Review]: Issue.IssueStatusEnum.Review,
    };
  }
  currentStatusToChangeTo = null;
  onChangeStatus(status: ApiResponseIssueEvent.DataEnum) {
    this.currentStatusToChangeTo = status;
    this.changeStatusDialog(status);
    // this.requestService.changeStatus(this.itemId, status).subscribe(red => {
    //     // this.requestService.navigateToListPage();
    //     this.data.issueStatus = this.statusChangeValue[status];
    //     this.getItemData(this.itemId);
    //     this.refreshRules();
    // })
  }
  escalationsEventList: any[] = [];
  escArray: string[] = [];
  eshArray: string[] = [];
  isLoadingArchivedEsc = false;
  archivedEscalations: {
    escalation: { [x: string]: EscalationDto };
    escalationHistory: { [x: string]: EscalationHistoryDto };
    escalationHistoryByEscCode: { [x: string]: EscalationHistoryDto };
  };
  constructor(
    public requestService: IssuesDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private appDialogService: AppDialogService,
    public localeService: LocaleService,
    public eschReqService: EscalationHistoryDataService,
    public escReqService: EscalationDataService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.Issue,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      router,
      requestService,
      toastService
    );

    combineLatest([this.activatedRoute.paramMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.itemId = data[0].get('id');
        this.editMode = !!this.itemId;
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(this.ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                    ' ' +
                    this.localeService.translate(
                      `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
                    ),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Issue,
        });
      });
    this.initRemediationCols();
  }
  initRemediationCols() {
    this.cols = [
      {
        name: 'Code',
        key: 'code',
        dataType: DataTypeEnum.CodeWithStatus,
        passRowAsData: true,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
        // frozen: true,
        alignFrozen: 'left',
      },
      {
        name: 'Record Status',
        key: 'recordStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RecordStatusEnumFilterWithoutDeleted,
        },
      },
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Owner',
        key: 'owner.name',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Status',
        key: 'status',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RemediationDto.StatusEnum,
        },
      },
      {
        name: 'Issues',
        key: 'issues',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Issue,
        },
      },
      {
        name: 'Approvers',
        key: 'approvers',
        dataType: DataTypeEnum.ResponsibilityListView,
        permissions: AppPermissions.ReadResponsibility,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Responsibility,
        },
      },
      {
        name: 'Progress',
        key: 'progress',
        dataType: DataTypeEnum.ProgressBar,
      },
      {
        name: 'start Date',
        key: 'startDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'Due Date',
        key: 'dueDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'actual Start Date',
        key: 'actualStartDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'actual Close Date',
        key: 'actualCloseDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    ];
  }
  changeStatusDialog(status) {
    this.requestService.changeStatus(this.itemId, status, { messages: {} }).subscribe((red) => {
      // this.requestService.navigateToListPage();
      this.data.issueStatus = this.statusChangeValue[status];
      this.getItemData(this.itemId);
      this.refreshRules();
    });
    // this.appDialogService.showDialog(BaseFormPopupComponent, "Change Status", (data) => {

    //     if (data) {
    //         this.requestService.changeStatus(this.itemId, status,{messages:{}}).subscribe(red => {
    //             // this.requestService.navigateToListPage();
    //             this.data.issueStatus = this.statusChangeValue[status];
    //             this.getItemData(this.itemId);
    //             this.refreshRules();
    //         })
    //     }
    // }, {
    //     data:
    //     {
    //         dynamicViewComponent: IssueChangeStatusFormComponent,
    //         dataService: this.requestService,
    //         filters: [],
    //         selectedRows: [],
    //         patchData: false,
    //         formData: {}
    //     }
    // })
  }
  onSetData() {
    if (this.data && this.data?.issueType?.escalationsDto?.length > 0) {
      let addedDays = this.data?.issueType?.resolutionDays || 0;
      let lastSelectedDate = new Date(this.data?.creationDate);
      const a = [...this.data?.issueType?.escalationsDto]?.sort((a, b) => (a.order || 0) - (b.order || 0));
      this.escalationsEventList = [
        { newAssignee: this.data?.assignee, date: new Date(this.data?.creationDate) },
        ...a.map((item, index) => {
          addedDays = addedDays + (item?.daysToResolve || 0);
          lastSelectedDate = this.data?.escalationsHistoryDto?.[index]?.creationDate
            ? new Date(this.data?.escalationsHistoryDto?.[index]?.creationDate)
            : lastSelectedDate;
          return {
            newAssignee: item?.assignee,
            date: this.data?.escalationsHistoryDto?.[index]?.creationDate
              ? new Date(this.data?.escalationsHistoryDto?.[index]?.creationDate)
              : new Date(new Date(lastSelectedDate).setMinutes(new Date(lastSelectedDate).getMinutes() + addedDays)),
          };
        }),
      ];
    }
  }
  archivedEventLists = [];
  toggleShowArchived() {
    if (this.archivedEventLists?.length <= 0 && !this.isLoadingArchivedEsc) {
      this.loadArchivedEvents();
    }
    this.showArchived = !this.showArchived;
  }
  loadArchivedEvents() {
    this.isLoadingArchivedEsc = true;
    this.archivedEventLists = [];
    this.escArray = [];
    this.eshArray = [];
    this.data?.escalationsArchiveItems?.forEach((archive) => {
      this.escArray.push(...archive.escalations);
      this.eshArray.push(...archive.escalationsHistory);
    });
    if (this.escArray?.length > 0) {
      forkJoin({
        esc: this.escReqService.search(
          { all: true },
          { filters: [{ property: 'code', operation: 'IN', value: this.escArray }] },
          { showLoading: false, showMsg: false }
        ),
        esh: this.eschReqService.search(
          { all: true },
          { filters: [{ property: 'code', operation: 'IN', value: this.eshArray }] },
          { showLoading: false, showMsg: false }
        ),
      }).subscribe({
        next: (res: any) => {
          this.archivedEscalations = {
            escalation: keyBy(res.esc, 'code'),
            escalationHistory: keyBy(res.esh, 'code'),
            escalationHistoryByEscCode: keyBy(res.esh, 'escalation'),
          };
          this.data?.escalationsArchiveItems?.forEach((archive, archiveIndex) => {
            let addedDays = this.data?.issueType?.resolutionDays || 0;
            const escArray = archive?.escalations?.map((x) => {
              return this.archivedEscalations?.escalation?.[x];
            });
            const eshArray = archive?.escalationsHistory?.map((x) => {
              return {
                [this.archivedEscalations?.escalationHistory?.[x]?.escalation]:
                  this.archivedEscalations?.escalationHistory?.[x],
              };
            });
            const a = escArray?.sort((a, b) => (a.order || 0) - (b.order || 0));
            const b = [
              {
                newAssignee: this.data?.assignee,
                date: new Date(this.data?.creationDate),
              },
              ...a.map((item, index) => {
                addedDays = addedDays + (item?.daysToResolve || 0);
                return {
                  newAssignee: item?.assignee,
                  date: eshArray?.[item?.code]?.creationDate
                    ? new Date(eshArray?.[item?.code]?.creationDate)
                    : new Date(
                        new Date(this.data?.creationDate).setMinutes(
                          new Date(this.data?.creationDate).getMinutes() + addedDays
                        )
                      ),
                };
              }),
            ];
            this.archivedEventLists?.push(b);
          });
          this.isLoadingArchivedEsc = false;
        },
        error: (err) => {},
      });
    }
  }
}
